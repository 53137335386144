import React from "react";
import logo from "../assets/logo.svg";
import {Container} from "reactstrap";

const Footer = () => (
  <footer className="bg-light p-3 text-center">
      <img className="mb-2 app-logo" src={logo} alt="CC Logo" width="40" />
      <p>
        {'⚡ Made with haste in hours ⚡'}
    </p>
  </footer>
);

export default Footer;
