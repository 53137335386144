import React, {useContext, useEffect, useState} from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import axios from 'axios'


const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [sheetData, setSheetData] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      auth0FromHook.getIdTokenClaims()
      setAuth0(auth0FromHook);

      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(isAuthenticated);

      if (isAuthenticated) {
        const user = await auth0FromHook.getUser();
        const token = await auth0FromHook.getTokenSilently()
        const claims = await auth0FromHook.getIdTokenClaims()
        let profile
        try {
          profile = await axios.get(` https://kd1m70s94b.execute-api.us-west-1.amazonaws.com/Master/user/${claims.sub}`)
        } catch(e) {
          if (e.response.status === 401) {
            auth0FromHook.logout({
              returnTo: 'https://milestones.kassenco.com'
            })
          }
        }


	const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
	const years = [2020, 2021, 2022]
        const start = 2
	const sheetNames = years.flatMap(year => {
		return months.map((month, i) => {
			return (year === 2020 && i < start) || (year === 2022 && i > (new Date()).getMonth()) ? null : `${month}%20${year}`
		}).filter(month => !!month)
	})
	const ranges = sheetNames.map(name => `ranges=${name}!a1%3ak200&`).join('')
        const sheetUrl = '1VbiEi59Gg6g3JXkxG95a314UokunR6lbSyb9XsiQh-w'
        const google = profile.data.identities.find(i => i.provider === 'google-oauth2')
        const sheetDataUrl = `https://sheets.googleapis.com/v4/spreadsheets/${sheetUrl}?${ranges}fields=properties.title,sheets(properties,data.rowData.values(effectiveValue,effectiveFormat))&includeGridData=True&access_token=${google.access_token}`
        let sheetDataResponse
        try {
          sheetDataResponse = await axios.get(sheetDataUrl)
        } catch(e) {
          if (e.response.status === 401 || e.response.status === 403) {
            auth0FromHook.logout({
              returnTo: 'https://milestones.kassenco.com'
            })
          }
        }
        if (sheetDataResponse.status === 200) {
          setSheetData(sheetDataResponse.data)
        }
        setUser(user);
      }

      setLoading(false);
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
      auth0Client.getUser()
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
          sheetData,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => {
		auth0Client.loginWithRedirect({
            prompt: 'login',
          connection: 'google-oauth2',
          connection_scope: 'https://www.googleapis.com/auth/spreadsheets'
		})},
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
