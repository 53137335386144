import React, {Component} from "react";

import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    NavLink,
    Nav,
    NavItem,
    TabPane,
    TabContent,
    CardText,
    Button
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import contentData from "../utils/contentData";
import {useAuth0} from "../react-auth0-spa";
import classnames from 'classnames'
import emoji from "../emoji";


function compileMilestones(sheetData) {
    if (!sheetData) {
        return {}
    }

    const {properties, sheets} = sheetData

    const tabs = sheets.map((sheet) => {
        const {properties: {title}, data: [{rowData: [header, ...rows]}]} = sheet
        const milestones = {}
        let currTeam
        rows.map(({values}) => {
            if (!values) {
                return
            } else if (currTeam && currTeam.toLowerCase() === 'metrics') {
                return
            }

            const ms = values[0]?.effectiveValue?.stringValue
            const completion = values[1]?.effectiveValue?.stringValue || values[1]?.effectiveValue?.numberValue
            const jira = values[2]?.effectiveValue?.stringValue

            if (!ms || ms.length === 0) {
                return
            }

            const msMatch = ms.match(/^- (.*)/i)
            const msMatchHeading = ms.match(/^# (.*)/i)
            if (msMatch) {
                currTeam = msMatch[1]
                if (currTeam.toLowerCase() !== 'metrics') {
                    milestones[currTeam] = []
                }
            } else if (msMatchHeading) {
                milestones[currTeam].push(
                    {
                        heading: true,
                        name: msMatchHeading[1],
                        completion: '',
                        jira: []
                    }
                )
            } else if (ms) {
                console.log(jira)
                milestones[currTeam].push(
                    {
                        name: ms,
                        completion: completion || '-',
                        jira: (jira || '').split(',').map(s => s.trim())
                    }
                )
            }
        })

        return {
            title,
            milestones
        }
    })

    return tabs

    //
    //
    //
    // const {values} = sheetData
    // const [headers, ...restValues] = values
    // const milestonesIdx = headers.indexOf('Milestones')
    // const completionIdx = headers.indexOf('Completion %')
    // const jiraIdx = headers.indexOf('Jira')
    // let currTeam
    // restValues.forEach((row) => {
    //     const ms = row[milestonesIdx]
    //     const completion = row[completionIdx]
    //     const jira = row[jiraIdx]
    //
    //     if (!ms) {
    //         return
    //     }
    //
    //     const msMatch = ms.match(/^- (.*)/i)
    //     if (msMatch) {
    //         currTeam = msMatch[1]
    //         milestones[currTeam] = []
    //     } else if (ms) {
    //         milestones[currTeam].push(
    //             {
    //                 name: ms,
    //                 completion: `${(completion || 0) * 100}%`,
    //                 jira: (jira || '').split(',').map(s => s.trim())
    //             }
    //         )
    //     }
    //
    // })
    // return milestones
}

function Team({children}) {
    return <Card style={{marginTop: 16}}>
        <CardBody>
            {children}
        </CardBody>
    </Card>
}

function Headers() {
    return <Row>
        <Col xs={'7'}><span style={{color: '#888'}}>Milestone</span></Col>
        <Col xs={'2'}><span style={{color: '#888'}}>Completion</span></Col>
        <Col xs={'3'}><span style={{color: '#888'}}>Context</span></Col>
    </Row>
}

function MilestoneRow(props) {
    const {heading, name, completion, jira, title} = props
    const toHash = title + name 
    const emojiPrefix = <>
        <span  style={{fontSize: 28, width: 42}}>{emoji[toHash.hashCode() % emoji.length]}</span>
    </>
    const prefix = heading ? <span style={{fontWeight: 'bold'}}>{name}</span> : <>{emojiPrefix}{name}</>
    return <Row>
        <Col xs={'7'} style={{
            display: 'flex',
            alignItems: 'center'
        }}>{prefix}</Col>
        <Col xs={'2'} style={{
            display: 'flex',
            alignItems: 'center'
        }}>{completion}</Col>
        <Col xs={'3'} style={{
            display: 'flex',
            alignItems: 'center'
        }}><JiraLinks jira={jira}/></Col>
    </Row>
}

function JiraLinks(props) {
    const {jira} = props
    return jira.map((issue, i) => {
        const match = issue.match(/(\w+)-(\d+)/)
        if (match) {
            return <>
                <a
                    href={`https://confidentcannabis.atlassian.net/browse/${issue}`}>
                    {issue}
                </a>
                {i < jira.length - 1 ? ', ' : null}
            </>
        } else {
            return issue
        }
    })
}

function Content() {
    const {sheetData} = useAuth0()
    const tabs = compileMilestones(sheetData)
    // const milestoneTeams = Object.keys(milestones)

    const [activeTab, setActiveTab] = React.useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        sheetData ? <div className="next-steps mt-3 mb-5">
            <NavLink className={'pl-0 mb-2'} href={'https://docs.google.com/spreadsheets/d/1VbiEi59Gg6g3JXkxG95a314UokunR6lbSyb9XsiQh-w/edit#gid=0'}>
                Edit Milestones Here
            </NavLink>
            <div>
                <Nav tabs>
                    {
                        tabs.map(({title}, i) => (<NavItem>
                            <NavLink
                                style={{cursor: 'pointer'}}
                                className={classnames({active: activeTab === String(i + 1)})}
                                onClick={() => {
                                    toggle(String(i + 1));
                                }}
                            >
                                {title}
                            </NavLink>
                        </NavItem>))
                    }
                </Nav>
                <TabContent activeTab={activeTab}>
                    {
                        tabs.map(({milestones, title}, i) => (<TabPane tabId={String(i+1)}>
                            {
                                Object.keys(milestones).map(team => {
                                    return <Team>
                                        <Row><Col><CardTitle style={{fontSize: 24, fontWeight: 'bold'}}>{team}</CardTitle></Col></Row>
                                        <Headers/>
                                        {
                                            milestones[team].map(milestone => {
                                                return <MilestoneRow title={title} {...milestone} />
                                            })
                                        }
                                    </Team>

                                })
                            }
                        </TabPane>))
                    }
                </TabContent>
            </div>

        </div> : null
    )
}

export default Content;
