import React from "react";

import logo from "../assets/logo.svg";
import {useAuth0} from "../react-auth0-spa";

const Hero = () => {
  const {sheetData} = useAuth0()
  return <div className="text-center hero my-3">
    <img className="mb-3 app-logo" src={logo} alt="React logo" width="120" />
    <h1 className="mb-4">Milestones</h1>

    {sheetData ? null : <p className="lead">
      Please Log In
    </p>}

  </div>
}


export default Hero;
